<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.error">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="body-2 text-first line-text-second pt-2 pb-6">
              <v-img 
                width="150"
                height="150"
                alt="utc" 
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <v-btn
                @click="sheet.add_reimburs = true; reset();"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img 
                    contain
                    alt="utc" 
                    :src="require('@/assets/icon/add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          size="15"
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                
                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Ajukan Reimbursement
                </span>
              </v-btn>
              
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box mt-6 mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-list 
                flat 
                three-line 
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.reimburs = item; sheet.detail_reimburs = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="item.status === 'Pengajuan' ? set_color : item.status === 'Diterima' ? 'green' : 'red'"
                                x-small>
                                {{ item.status }}
                              </v-chip>
                            </v-list-item-subtitle>
                            
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>
                              
                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold">
                              {{ item.merchant }}
                            </v-list-item-title>

                            <v-list-item-subtitle 
                              class="body-1 my-1"
                              :style="`color: ${set_color};`">
                              <span
                                class="line-text-first one-line">
                                {{ item.total | price }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle 
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Reimbursement" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_reimburs"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.add_reimburs = false; reset();"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Tambah Reimbursement
              </span>
            </v-card-title>

            <v-card-text 
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Periode
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="submission"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.submission">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider 
                            name="Periode" 
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              readonly
                              solo
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. Januari 2023"
                              
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.month_year = ''; form.month_year_format = '';"
                              v-on="on"
                              v-bind="attrs"

                              v-model="form.month_year_format"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker 
                          type="month"
                          :color="set_color"
                          v-model="form.month_year"
                          @input="picker.submission = false; to_plafon();">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <v-row>
                      <v-col
                        cols="7"
                        class="py-0">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tanggal
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-menu
                          ref="visit"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.visit">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider 
                              name="Tanggal" 
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save()"

                                readonly
                                solo
                                class="no_box"
                                :color="set_color"
                                placeholder="Contoh. 01 Januari 2023"
                                
                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.visit_date = ''; form.visit_date_format = '';"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.visit_date_format"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker 
                            :color="set_color"
                            v-model="form.visit_date"
                            @input="picker.visit = false; to_plafon();">
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col
                        cols="5"
                        class="py-0">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Waktu
                          <span class="error--text">
                            (wajib diisi)
                          </span>
                        </div>

                        <v-menu
                          ref="visit"
                          v-model="time.visit"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="form.visit"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider 
                              name="Waktu" 
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save()"

                                readonly
                                solo
                                class="no_box"
                                :color="set_color"
                                placeholder="Contoh. 15:00"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form.visit_time =  ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form.visit_time"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-time-picker
                            v-if="time.visit"
                            v-model="form.visit_time"
                            full-width
                            :color="set_color"
                            format="24hr"
                            @click:minute="$refs.visit.save(`${form.visit_time}`)">
                          </v-time-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <ValidationProvider 
                      name="Kategori" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kategori
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Transportasi"
                        
                        :items="category"
                        item-text="title"
                        item-value="id"
                        :item-color="set_color"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        @change="to_plafon()"

                        v-model="form.category_id"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="Merchant" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Merchant
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Kopi Gati"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.merchant"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Sisa Plafon Saat Ini" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Sisa Plafon Saat Ini
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-currency-field
                        type="number"
                        onkeypress="return event.charCode >= 45 && event.charCode <= 57"

                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 50.000"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        disabled

                        @focus="$event.target.select()"
                        
                        v-model="form.sisa_plafon"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-currency-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Nominal Ajuan" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nominal Ajuan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-currency-field
                        type="number"
                        onkeypress="return event.charCode >= 45 && event.charCode <= 57"

                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 20.000"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        @focus="$event.target.select()"
                        
                        v-model="form.total"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-currency-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Total Sisa Plafon" 
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Total Sisa Plafon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-currency-field
                        type="number"
                        onkeypress="return event.charCode >= 45 && event.charCode <= 57"

                        class="no_box"
                        solo
                        :color="set_color"
                        placeholder="Contoh. 30.000"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        disabled

                        @focus="$event.target.select()"
                        
                        v-model="form.total_sisa_plafon"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-currency-field>
                    </ValidationProvider>

                    <div
                      class="mb-6">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Upload Bukti
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <uploadfolder
                        v-model="upload.folder" 
                        style="height: 250px;">
                        <div 
                          slot="activator"
                          class="cursor-pointer">
                          <v-card 
                            flat
                            height="250"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea; 
                                  border-style: dashed;">
                            <div v-if="form.file_url.length < 1">
                              <v-icon v-if="!process.image"
                                size="65" 
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>

                              <v-progress-circular v-else
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </div>

                            <v-img v-else
                              contain
                              height="250"
                              :src="form.file_url" 
                              :alt="form.file_url"
                              class="text-center d-flex align-center">
                              <div 
                                class="d-flex aling-center justify-center" 
                                style="width: 100%; height: auto;">
                                <v-icon v-if="!process.image"
                                  size="65" 
                                  color="white">
                                  mdi-cloud-upload-outline
                                </v-icon>

                                <v-progress-circular v-else
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </div>
                            </v-img>
                          </v-card>
                        </div>
                      </uploadfolder>
                    </div>

                    <ValidationProvider 
                      name="Keterangan" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save()"
                        
                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.desc"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="utc" 
                      :src="require('@/assets/image/profile/reimburs.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Silahkan isi plafon untuk mengajukan reimbursement Anda, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"
                      
                      rounded
                      block
                      large
                      color="#348d37"
                      
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="sheet.add_reimburs = false; reset();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined
                      
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_reimburs"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title 
              @click="sheet.detail_reimburs = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon 
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Laporan
              </span>
            </v-card-title>
            
            <v-card-text v-if="Object.keys(selected.reimburs).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>
                
                <span
                  class="ml-1">
                  {{ selected.reimburs.datetime | date }} Pukul {{ selected.reimburs.datetime | time }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.reimburs.merchant }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Nominal Ajuan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.reimburs.total | price }}
              </div>
              
              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.reimburs.desc !== '' ? selected.reimburs.desc : '-' }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Bukti:
              </div>

              <div
                class="mb-6">
                <div v-if="selected.reimburs.file_url !== ''">
                  <v-img
                    contain
                    alt="utc" 
                    width="100%"
                    height="auto"
                    :src="selected.reimburs.file_url"
                    class="ma-auto border-radius">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular 
                          indeterminate 
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>

                <div v-else
                  class="pa-4"
                  style="border: 2px dashed #eaeaea; border-radius: 10px;">
                  Data lampiran tidak tersedia!!!
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import moment from 'moment'
  import "moment/locale/id"
  export default {
    data () {
      return {
        dialog: {
          error: false
        },

        sheet: {
          add_reimburs: false,
          detail_reimburs: false
        },

        selected: {
          reimburs: {}
        },

        search: '',

        category: [],

        list: [],
        pagination: {},
        limit: 10,

        form: {
          category_id: '',

          month: '',
          year: '',
          month_year: '',
          month_year_format: '',

          visit_date: '',
          visit_date_format: '',
          visit_time: '',
          datetime: '',

          reimburse_plafon_id: '',
          merchant: '',
          desc: '',
          file_url: '',

          sisa_plafon: 0,
          total: 0,
          total_sisa_plafon: 0
        },

        picker: {
          submission: false,
          visit: false
        },

        time: {
          visit: false
        },

        upload: {
          folder: null
        },

        process: {
          load: false,
          form: false,

          limit: false,

          image: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true
        
        this.fetch('limit')
      },

      'form.month_year': function(val) {
        this.form.month_year_format = moment(val).format('MMMM YYYY')
      },

      'form.visit_date': function(val) {
        this.form.visit_date_format = moment(val).format('DD, MMMM YYYY')
      },

      'form.visit_time': function() {
        this.form.datetime = `${this.form.visit_date} ${this.form.visit_time}`
      },

      'form.total': function(val) {
        this.form.total_sisa_plafon = parseInt(this.form.sisa_plafon) - parseInt(val)
      },

      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.image = this.upload.folder.process

            if (!this.process.image) {
              // CALLBACK FROM UPLOAD DOC
              
              this.form.file_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false
        
        let params = {
          limit: this.limit,
          search: this.search,

          sort: '-id'
        }

        await this.$axios.$get(`${process.env.API_URL}user/reimburse/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchCategory () {
        await this.$axios.$get(`${process.env.API_URL}user/reimburse_category/data`)
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.category = response.results.data
          }
        })
      },

      async fetchPlafon () {
        let params = {
          category_id: this.form.category_id,
          month: this.form.month,
          year: this.form.year
        }

        await this.$axios.$get(`${process.env.API_URL}user/reimburse/plafon`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            let data = response.results.data

            this.form.reimburse_plafon_id = Object.keys(data).length > 0 ? parseInt(data.id) : ''
            this.form.sisa_plafon = Object.keys(data).length > 0 ? parseInt(data.sisa_value) : 0
          }
        })
      },

      to_plafon () {
        this.reimburse_plafon_id = ''
        this.form.sisa_plafon = 0

        if (this.form.month_year !== '' && this.form.category_id !== '') {

          this.form.year = this.form.month_year !== '' ? this.form.month_year.split('-')[0] : ''
          this.form.month = this.form.month_year !== '' ? this.form.month_year.split('-')[1] : ''

          this.fetchPlafon()
        }
      },

      reset () {
        if (this.$refs.form) this.$refs.form.reset()
        
        this.form = {
          category_id: '',

          month: '',
          year: '',
          month_year: '',
          month_year_format: '',

          visit_date: '',
          visit_date_format: '',
          visit_time: '',
          datetime: '',

          reimburse_plafon_id: '',
          merchant: '',
          desc: '',
          file_url: '',

          sisa_plafon: 0,
          total: 0,
          total_sisa_plafon: 0
        }

        this.picker = {
          submission: false,
          visit: false
        }

        this.time = {
          visit: false
        }

        this.process.form = false

        this.message.error = ''

        if (this.sheet.add_reimburs) this.fetchCategory()
      },
      
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/reimburse/pengajuan`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_reimburs = false
              
              this.fetch()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>