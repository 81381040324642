<template>
  <div>
    <v-card
      flat
      min-height="100vh"
      class="d-flex align-center"
      style="z-index: 1;">
      <v-card-text>
        <div
          class="text-h4 text-center font-weight-bold mb-2"
          :style="`color: #33691e`">
          <!-- UCT -->
        </div>

        <div
          class="body-1 text-center">
          <!-- Sistem Produk HRIS UCT -->
          Sistem Produk HRIS
        </div>

        <div
          class="px-16 ma-6">
          <v-progress-linear
            indeterminate
            height="10"
            rounded
            color="#33691e">
          </v-progress-linear>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      set_color () {
        return this.$store.state.header.config.color
      }
    },
  }
</script>