<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="pt-2 pb-6">
              <v-img 
                width="125"
                height="125"
                alt="utc" 
                :src="require('@/assets/icon/info_berhasil.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                <!-- Terima kasih, Anda telah melengkapi data pribadi di UCT. -->
                Terima kasih, Anda telah melengkapi data pribadi di HRIS.
              </div>
            </v-card-text>

            <v-divider 
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                href="/profile"
                @click="dialog.success = false"
                
                rounded
                outlined
                color="red"
                elevation="0"

                class="text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.error">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="pt-2 pb-6">
              <v-img 
                width="150"
                height="150"
                alt="utc" 
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="transparent">
            <v-card-text
              class="pb-2 d-flex align-center">
              <v-avatar 
                tile
                size="150">
                <v-img 
                  contain
                  style="border: 5px solid #0000000d;
                        border-radius: 50px;"
                  alt="utc" 
                  :src="form.image_url === '' ? require('@/assets/image/member_default.png') : form.image_url"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular 
                        indeterminate 
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <div>
                <v-btn
                  @click="sheet.option = true"
                  :disabled="process.file"
                  :loading="process.file"

                  large
                  rounded
                  block
                  elevation="0"
                  color="#0000000d"

                  class="body-1 font-weight-bold text-capitalize d-flex align-center"
                  style="border-radius: 0px 50px 50px 0px;"
                  :style="`color: ${set_color};`">
                  <v-icon
                    size="24"
                    :color="set_color">
                    mdi-camera
                  </v-icon>

                  <span
                    class="ml-1">
                    Ubah Foto
                  </span>
                </v-btn>
              </div>
            </v-card-text>

            <v-card-text
              class="mb-16 pb-16">
              <div 
                class="mb-16">
                <ValidationObserver 
                  ref="form">
                  <v-form>
                    <ValidationProvider 
                      name="name" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Nama Lengkap
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. John Doe"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.name"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="username" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Username
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. johndoe"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        
                        v-model="form.username"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="mobilephone" 
                      rules="required|min:8|max:13"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Telepon
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>
                      
                      <v-text-field
                        v-on:keyup.enter="save()"

                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. 081332XXXXXX"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.mobilephone"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="address" 
                      rules="required" 
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Alamat
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save()"
                        
                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Kradenan, Trucuk, Klaten"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.address"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card v-if="!sheet.option"
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="utc" 
                      :src="require('@/assets/image/profile/profile.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Ubah data diri Anda untuk informasi akun di layanan kami, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      large
                      block
                      color="#348d37"
                      
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="$emit('close', false)"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>

          <v-bottom-sheet
            v-model="sheet.option"
            hide-overlay>
            <v-card
              flat
              color="#fff"
              class="d-flex align-center"
              style="border-radius: 30px 30px 0px 0px;">
              <v-card-text
                class="pa-3">
                <v-row 
                  align="center" 
                  justify="center">
                  <!-- CAMERA -->
                  <v-col 
                    cols="4" 
                    class="text-center">
                    <div @click="sheet.upload = true; selected.type = 'camera';"
                      class="cursor-pointer">
                      <v-avatar 
                        size="50" 
                        class="cursor-pointer" 
                        v-ripple
                        style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                        <v-img 
                          alt="utc" 
                          :src="require('@/assets/image/camera.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular 
                                indeterminate 
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div 
                        class="font-weight-bold black--text mt-2">
                        Kamera
                      </div>
                    </div>
                  </v-col>

                  <v-col 
                    cols="4" 
                    class="text-center px-0">
                    <div 
                      class="d-flex align-center">
                      <v-divider 
                        style="border: 2px solid #eaeaea; border-radius: 50px;" />

                      <div 
                        class="mx-2 font-weight-bold"
                        style="opacity: 80%;">
                        Atau
                      </div>

                      <v-divider 
                        style="border: 2px solid #eaeaea; border-radius: 50px;" />
                    </div>
                  </v-col>

                  <!-- FOLDER -->
                  <v-col 
                    cols="4" 
                    class="text-center">
                    <upload-folder 
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-avatar 
                          size="50" 
                          class="cursor-pointer" 
                          v-ripple
                          style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                          <v-img 
                            alt="utc" 
                            :src="require('@/assets/image/folder.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular 
                                  indeterminate 
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>

                        <div 
                          class="font-weight-bold black--text mt-2">
                          Galeri
                        </div>
                      </div>
                    </upload-folder>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>

          <v-bottom-sheet
            v-model="sheet.upload"
            hide-overlay
            persistent>
            <upload-camera v-if="selected.type === 'camera'"
              :sheet="sheet.upload"
              @frame="sheet.upload = $event"
              @input="addCamera($event)"
              @close="sheet.upload = $event; selected.type = '';" />
          </v-bottom-sheet>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dialog: {
          success: false,
          error: false
        },
        selected: {
          type: ''
        },
        sheet: {
          option: false,
          upload: false
        },
        upload: {
          camera: null,
          folder: null
        },
        form: {
          name: '',
          address: '',
          mobilephone: '',
          username: '',
          image_url: ''
        },
        process: {
          form: false,
          file: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      UploadCamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE
              this.sheet.option = false

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      }
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.reset()
    },
    methods: {
      addCamera (e) {
        this.process.file = e.process

        if (!e.process) {
          this.form.image_url = e.fileuri

          this.sheet.option = false
        } 
      },
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = this.detail

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/profile/update`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              
              this.$emit('close', false)

              Cookie.set('user', JSON.stringify(response.results))

              this.dialog.success = true
            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>