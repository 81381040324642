<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.error">
          <v-card
            style="border-radius: 30px;">
            <v-card-text
              class="pt-2 pb-6">
              <v-img
                width="150"
                height="150"
                alt="utc"
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-6 pb-4">
              <div>
                <v-select
                  solo
                  class="no_box"
                  :color="set_color"
                  placeholder="Pilih Kategori"

                  :items="category"
                  item-text="title"
                  item-value="id"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.category_id = ''"

                  v-model="filter.category_id">
                </v-select>

                <v-menu
                  ref="periode"
                  min-width="290px"
                  max-width="290px"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  :close-on-content-click="false"
                  v-model="picker.periode">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      readonly
                      class="no_box"
                      :color="set_color"
                      placeholder="Pilih Periode"

                      clearable
                      @click:clear="filter.periode = ''; filter.periode_format = '';"

                      hide-details

                      v-on="on"
                      v-bind="attrs"

                      v-model="filter.periode_format">
                    </v-text-field>
                  </template>

                  <v-date-picker
                    type="month"
                    :color="set_color"
                    v-model="filter.periode"
                    @input="picker.periode = false">
                  </v-date-picker>
                </v-menu>
              </div>
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"

                rounded
                color="#348d37"

                class="font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Filter
              </v-btn>

              <v-btn
                @click="dialog.filter = false"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <v-btn
                @click="sheet.add_report = true; reset();"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img
                    contain
                    alt="utc"
                    :src="require('@/assets/icon/add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="15"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Buat Laporan Harian
                </span>
              </v-btn>

              <div
                class="d-flex align-center mt-6 mb-5">
                <v-text-field
                  v-model="search"
                  @click:clear="search = ''; fetch();"
                  v-on:keyup.enter="fetch()"
                  class="no_box"
                  solo
                  :color="set_color"
                  single-line
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="dialog.filter = true; fetchCategory();"
                  fab
                  outlined
                  color="#e0e0e0"
                  width="48"
                  height="48"
                  class="ml-4">
                  <v-avatar
                    size="18"
                    tile>
                    <v-icon color="#6f6f6f">mdi-filter-multiple-outline</v-icon>
                  </v-avatar>
                </v-btn>
              </div>

              <!-- <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box mt-6 mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field> -->

              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.report = item; sheet.detail_report = true;"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="set_color"
                                x-small>
                                {{ item.category_title }}
                              </v-chip>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Laporan" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.add_report = false; reset();"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Tambah Laporan
              </span>
            </v-card-title>

            <v-card-text
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver
                  ref="form">
                  <v-form>
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Judul
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Besok Rapat"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="category_id"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kategori
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Aktifitas"

                        :items="category"
                        item-text="title"
                        item-value="id"
                        :item-color="set_color"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.category_id"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <div
                      class="mb-6">
                      <div class="body-2">
                        Lampiran
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-row>
                        <v-col
                          cols="6">
                          <uploadfolder
                            v-model="upload.folder">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.file || process.doc"
                                :loading="process.file"

                                rounded
                                large
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="utc"
                                    :src="require('@/assets/icon/upload_image.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload Gambar
                                </span>
                              </v-btn>
                            </div>
                          </uploadfolder>
                        </v-col>

                        <v-col
                          cols="6">
                          <uploaddocument
                            v-model="upload.doc">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.doc || process.file"
                                :loading="process.doc"

                                rounded
                                large
                                block
                                :color="`${set_color_rgb_soft}`"
                                elevation="0"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="utc"
                                    :src="require('@/assets/icon/upload_folder.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload PDF
                                </span>
                              </v-btn>
                            </div>
                          </uploaddocument>
                        </v-col>
                      </v-row>

                      <div v-if="form.attachment === ''"
                        class="line-text-second font-italic"
                        style="opacity: 70%;">
                        Upload Gambar atau PDF untuk melengkapi data lampiran dari laporan Anda.
                      </div>

                      <div v-if="form.attachment !== ''"
                        class="mt-2">
                        <div v-if="form.attachment.split('.').pop() !== 'pdf'">
                          <v-img
                            contain
                            alt="utc"
                            width="100%"
                            height="auto"
                            :src="form.attachment"
                            class="ma-auto border-radius">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div v-else>
                          <iframe
                            :src="`${form.attachment}#toolbar=0`"
                            allowfullscreen
                            frameborder="0"
                            width="100%"
                            height="397"
                            style="border-radius: 15px; border: 2px solid #323639;">
                          </iframe>
                        </div>
                      </div>

                      <!-- <uploadfolder
                        v-model="upload.folder">
                        <div
                          slot="activator"
                          class="cursor-pointer">
                          <v-card
                            flat
                            width="100%"
                            height="260"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea;
                                  border-style: dashed;">
                            <v-img v-if="!process.file && form.attachment !== ''"
                              contain
                              alt="utc"
                              width="100%"
                              height="256"
                              :src="form.attachment"
                              class="ma-auto d-flex align-center text-center">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-icon
                                size="65"
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>
                            </v-img>

                            <v-icon v-if="!process.file && form.attachment === ''"
                              size="65"
                              color="grey">
                              mdi-cloud-upload-outline
                            </v-icon>

                            <v-progress-circular v-if="process.file"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-card>
                        </div>
                      </uploadfolder> -->
                    </div>

                    <ValidationProvider
                      name="desc"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.desc"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row
                  align="center">
                  <v-col
                    cols="3"
                    class="pb-0">
                    <v-img
                      contain
                      alt="utc"
                      :src="require('@/assets/icon/report.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col
                    cols="9"
                    class="black--text pb-0">
                    <div>
                      <div
                        class="body-2 line-text-second black--text"
                        style="opacity: 60%;">
                        Silahkan isi laporan kegiatan yang Anda lakukan hari ini, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-4">
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_report = false; reset();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_report = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Laporan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.report).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>

                <span
                  class="ml-1">
                  {{ selected.report.datetime | date }} Pukul {{ selected.report.datetime | time }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.report.title }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Kategori:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.category_title }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.desc !== '' ? selected.report.desc : '-' }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lampiran:
              </div>

              <div
                class="mb-6">
                <!-- <v-img
                  width="100%"
                  height="auto"
                  alt="utc"
                  class="border-radius"
                  :src="selected.report.attachment">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->

                <div v-if="selected.report.attachment !== ''">
                  <div v-if="selected.report.attachment.split('.').pop() !== 'pdf'">
                    <v-img
                      contain
                      alt="utc"
                      width="100%"
                      height="auto"
                      :src="selected.report.attachment"
                      class="ma-auto border-radius">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div v-else>
                    <iframe
                      :src="`${selected.report.attachment}#toolbar=0`"
                      allowfullscreen
                      frameborder="0"
                      width="100%"
                      height="397"
                      style="border-radius: 15px; border: 2px solid #323639;">
                    </iframe>
                  </div>
                </div>

                <div v-else
                  class="pa-4"
                  style="border: 2px dashed #eaeaea; border-radius: 10px;">
                  Data lampiran tidak tersedia!!!
                </div>
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lokasi:
              </div>

              <div>
                <gmap-map
                  ref="gmap"
                  :center="center"
                  :zoom="15"
                  :scrollwheel="false"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  style="width:100%; height: 30vh;">
                  <gmap-marker
                    :position="currentPosition.latlng"
                    :icon="currentPosition.icon">
                  </gmap-marker>
                </gmap-map>
              </div>
            </v-card-text>

            <!-- <v-card
              flat
              color="#FFFFFF"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0">
                    <v-btn
                      @click="sheet.detail_summary = true; fetchSummary();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      :color="set_color"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-history</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Summary
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> -->
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_summary"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_summary = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Summary Laporan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.summary).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="title font-weight-bold black--text text-center mb-1">
                {{ selected.summary.user_name }}
              </div>

              <div
                class="body-1 black--text text-center">
                Total: {{ selected.summary.total }}
              </div>

              <v-divider
                class="my-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <v-text-field
                v-model="search_history"
                @click:clear="search_history = ''; fetchHistory();"
                v-on:keyup.enter="fetchHistory()"
                class="no_box mt-6 mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : history"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && history.length > 0"
                          @click="selected.report = item; sheet.detail_report = true; sheet.detail_summary = false;"
                          :class="index < history.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="set_color"
                                x-small>
                                {{ item.category_title }}
                              </v-chip>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination_history.total_data > 10 && limit_history < pagination_history.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit_history += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination_history.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Riwayat Laporan" />
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          error: false,

          filter: false
        },

        filter: {
          category_id: '',
          periode: '',
          periode_format: ''
        },

        picker: {
          periode: false
        },

        sheet: {
          add_report: false,
          detail_report: false,

          detail_summary: false
        },

        selected: {
          report: {},

          summary: {}
        },

        search: '',
        search_history: '',

        category: [],
        history: [],
        list: [],
        pagination: {},
        pagination_history: {},

        limit: 10,
        limit_history: 10,

        center: {
          lat: null,
          lng: null
        },
        currentPosition: {},

        upload: {
          folder: null,
          doc: null
        },

        form: {
          title: '',
          desc: '',
          latitude: '',
          longitude: '',
          attachment: '',

          category_id: ''
        },

        process: {
          load: false,
          form: false,
          file: false,
          doc: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/report',
          title: 'Laporan',
          description: 'Laporan UCT',
          image: require('@/assets/logo/favicon.jpg')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      uploaddocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.attachment = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'upload.doc': {
        handler: function () {
          if (this.upload.doc) {
            this.process.doc = this.upload.doc.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD DOC

              this.form.attachment = this.upload.doc.fileuri
            }
          }
        },
        deep: true
      },

      'selected.report': function(val) {
        this.$gmapApiPromiseLazy().then(() => {
          this.center = {
            lat: parseFloat(val.latitude),
            lng: parseFloat(val.longitude)
          }

          this.currentPosition = {
            latlng: {
              lat: parseFloat(val.latitude),
              lng: parseFloat(val.longitude)
            },
            icon: {
              url: require('@/assets/icon/location_pin.png'),
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(25, 50)
            }
          }
        })
      },

      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      },

      'limit_history': function() {
        this.process.limit = true

        this.fetchHistory('limit')
      },

      'filter.periode': function(val) {
        this.filter.periode_format = moment(val).format('MMMM YYYY')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.05)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit,
          search: this.search,

          category_id: this.filter.category_id,
          datetime: this.filter.periode
        }

        await this.$axios.$get(`${process.env.API_URL}user/report/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      async fetchCategory () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.API_URL}admin/task_category/data`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.category = response.results.data
          }
        })
      },

      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        navigator.geolocation.getCurrentPosition(position => {
          this.form = {
            title: '',
            desc: '',
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            attachment: '',

            category_id: ''
          }
        })

        this.process.form = false

        this.message.error = ''

        if(this.sheet.add_report) this.fetchCategory()
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/report/add`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_report = false

              this.fetch()
            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async fetchSummary () {
        this.process.load = true

        await this.$axios.$get(`${process.env.API_URL}user/report/summary`)
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.selected.summary = response.results.data.length > 0  ? response.results.data[0] : {}

            if (response.results.data.length > 0) this.fetchHistory()
          }
        })
      },

      async fetchHistory (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          limit: this.limit_history,
          search: this.search_history,

          user_id: this.selected.summary.user_id
        }

        await this.$axios.$get(`${process.env.API_URL}user/report/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.history = response.results.data
            this.pagination_history = response.results.pagination
          }
        })
      }
    }
  }
</script>
