<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <section>
          <v-row
            align="center"
            style="height: 100vh;">
            <v-col>
              <div
                class="mb-8">
                <v-img
                  contain
                  width="175"
                  height="175"
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        color="#33691e">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <div
                  class="text-h4 font-weight-bold text-center"
                  style="position: relative; top: -25px;"
                  :style="`color: #33691e;`">
                  <!-- UCT -->
                </div>
              </div>

              <v-card
                flat
                color="transparent">
                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="username"
                        rules="required"
                        v-slot="{ errors }">
                        <v-text-field
                          v-on:keyup.enter="save()"

                          solo
                          class="no_box"
                          color="#33691e"
                          placeholder="Email"
                          prepend-inner-icon="mdi-email"

                          v-model="form.username"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="password"
                        rules="required|min:8|max:20"
                        v-slot="{ errors }">
                        <v-text-field
                          v-on:keyup.enter="save()"

                          :type="show ? 'text' : 'password'"

                          solo
                          class="no_box"
                          color="#33691e"
                          placeholder="Password"
                          prepend-inner-icon="mdi-lock"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"

                          @click:append="show = !show"
                          :counter="20"

                          v-model="form.password"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-4">
                    <div
                      class="error--text d-flex align-start">
                      <v-icon size="18" color="red" style="margin-top: 3px;">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-2">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>

                <v-card-text>
                  <!-- <div
                    class="body-1 text-center"
                    style="opacity: 80%;">
                    Lupa password ?
                  </div> -->

                  <div
                    class="text-center my-6">
                    <v-btn
                      @click="save()"
                      v-on:keyup.enter="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      x-large
                      color="#33691e"

                      class="font-weight-bold white--text text-capitalize"
                      style="font-size: 18px;">
                      Masuk
                    </v-btn>
                  </div>

                  <div
                    class="body-1 d-flex align-center justify-center"
                    style="opacity: 80%;">
                    <v-icon small>mdi-copyright</v-icon>

                    <span
                      class="ml-1">
                      PT Solusi Digital Manunggal
                    </span>

                    <!-- Powered by PT Solusi Digital Manunggal -->
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'notAuthenticated',
    data () {
      return {
        show: false,
        form: {
          username: '',
          password: '',
          device: 'web',
          fcm_token: ''
        },
        process: {
          load: false,
          form: false
        },
        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/',
          title: 'Masuk',
          description: 'Masuk UCT',
          image: require('@/assets/logo/favicon.jpg')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {

    },
    watch: {

    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      fcm () {
        return this.$store.state.member.auth.fcm
      },
      token() {
        return this.$store.state.member.auth.token
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r+35}, ${g+37}, ${b}, 0.97)`
      }
    },
    mounted () {
      this.fetchCompany()
    },
    methods: {
      async fetchCompany () {
        let params = {
          company_id: '28'
        }

        await this.$axios.$get(`${process.env.API_URL}config`, { params })
        .then((response) => {
          if (response.status === 200) {
            response.results.data[0].color = JSON.parse(response.results.data[0].color)

            this.$store.commit('header/config/setCompany', response.results.data[0])
            Cookie.set('company', response.results.data[0])

            if (response.results.data[0].color.length > 0) {
              response.results.data[0].color.map((obj) => {
                if (obj.active) {
                  this.$store.commit('header/config/setColor', obj.hex)

                  Cookie.set('color', obj.hex)
                }
              })
            } else {
              this.$store.commit('header/config/setColor', '#7550e1')

              Cookie.set('color', '#7550e1')
            }

            // this.styles()
          }
        })
      },
      async save () {
        this.message.error = ''

        this.form.fcm_token = this.fcm

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/auth/login`, this.form)
          .then((response) => {
            if (response.status === 200) {

              Cookie.set('token', response.results.token)
              Cookie.set('user', JSON.stringify(response.results.data))
              Cookie.set('popup', true)

              window.location = '/home'

            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
