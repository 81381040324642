<template>
  <div class="fill-height pb-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }} Berhasil
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-2">
              Terima kasih, Anda telah melakukan presensi {{ form.type }}.
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.success = false"

                rounded
                color="red"
                outlined

                class="text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.form"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }}
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-2">
              <div
                class="mb-4">
                Apakah Anda yakin akan melakukan presensi {{ form.type }}?
              </div>

              <!-- <v-select v-if="have_shift && form.type === 'datang'"
                filled
                rounded
                dense

                :color="set_color"
                placeholder="Pilih Shift"

                hide-details

                :items="shift"
                item-value="id"
                item-text="name"
                :item-color="set_color"

                clearable
                @click:clear="form.shift_id = ''"

                v-model="form.shift_id"
                :success="form.shift_id !== '' && form.shift_id !== null"
                class="mb-1"
                style="border-radius: 10px;">
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }} ({{ `${data.item.start_time.split(':')[0]}:${data.item.start_time.split(':')[1]}` }} - {{ `${data.item.end_time.split(':')[0]}:${data.item.end_time.split(':')[1]}` }})
                </template>
                <template slot="item" slot-scope="data">
                  {{ data.item.name }} ({{ `${data.item.start_time.split(':')[0]}:${data.item.start_time.split(':')[1]}` }} - {{ `${data.item.end_time.split(':')[0]}:${data.item.end_time.split(':')[1]}` }})
                </template>
              </v-select> -->
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.form = false; sheet.camera_attendance = true; selected.type_attendance = 'camera';"

                rounded
                color="#348d37"

                class="font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn
                @click="dialog.form = false"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.option"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Presensi {{ form.type }}
              </span>

              <!-- <v-spacer/>

              <v-icon
                @click="dialog.option = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon> -->
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-0">
              <v-row
                align="center"
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div
                    v-on:keyup.enter="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                    @click="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                    class="cursor-pointer">
                    <v-avatar
                      size="50"
                      class="cursor-pointer"
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img
                        alt="utc"
                        :src="require('@/assets/image/camera.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div
                      class="font-weight-bold black--text mt-2">
                      Kamera
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="4"
                  class="text-center px-0">
                  <div
                    class="d-flex align-center">
                    <v-divider
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col
                  cols="4"
                  class="text-center">
                  <div
                    v-on:keyup.enter="saveCheckInOut()"
                    @click="saveCheckInOut()"
                    class="cursor-pointer">
                    <v-avatar
                      size="50"
                      class="cursor-pointer"
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img
                        alt="utc"
                        :src="require('@/assets/image/camera_block.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div
                      class="font-weight-bold black--text mt-2">
                      Lewati
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <!-- <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="saveCheckInOut()"
                @click="saveCheckInOut()"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                @click="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn> -->

              <v-btn
                @click="dialog.option = false"
                :disabled="process.form"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.permission"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Permission Telah di block
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-2">
              Silahkan aktifkan permission lokasi di perangkat Anda terlebih dahulu.
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.permission = false"

                rounded
                color="red"
                outlined

                class="text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.alasan"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Alasan
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3">
              <span v-if="detail.status === 'ijin'">
                Anda hari ini sedang izin{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
              </span>

              <span v-if="detail.status === 'leave'">
                Anda hari ini sedang meinggalkan jam kerja{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
              </span>
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.alasan = false"

                rounded
                color="red"
                outlined

                class="text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Tutup
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.error">
          <v-card
            style="border-radius: 30px;">
            <v-card-text
              class="pt-2 pb-6">
              <v-img
                width="150"
                height="150"
                alt="utc"
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-6 pb-4">
              <div>
                <v-select
                  solo
                  class="no_box"
                  :color="set_color"
                  placeholder="Pilih Kategori"

                  :items="category"
                  item-text="title"
                  item-value="id"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.category_id = ''"

                  v-model="filter.category_id">
                </v-select>

                <v-menu
                  ref="periode"
                  min-width="290px"
                  max-width="290px"
                  transition="scale-transition"
                  offset-y
                  :nudge-right="40"
                  :close-on-content-click="false"
                  v-model="picker.periode">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      solo
                      readonly
                      class="no_box"
                      :color="set_color"
                      placeholder="Pilih Periode"

                      clearable
                      @click:clear="filter.periode = ''; filter.periode_format = '';"

                      hide-details

                      v-on="on"
                      v-bind="attrs"

                      v-model="filter.periode_format">
                    </v-text-field>
                  </template>

                  <v-date-picker
                    type="month"
                    :color="set_color"
                    v-model="filter.periode"
                    @input="picker.periode = false">
                  </v-date-picker>
                </v-menu>
              </div>
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetchReport();"

                rounded
                color="#348d37"

                class="font-weight-bold text-capitalize white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Filter
              </v-btn>

              <v-btn
                @click="dialog.filter = false"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.date"
          persistent>
          <v-card
            style="border-radius: 30px;">
            <v-card-title
              class="d-flex align-center pa-4 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="utc"
                  :src="require('@/assets/logo/favicon.jpg')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Pilih Tanggal Presensi
              </span>

              <!-- <v-spacer/>

              <v-icon
                @click="dialog.option = false"
                class="cursor-pointer"
                size="24"
                color="red">
                mdi-close-circle-outline
              </v-icon> -->
            </v-card-title>

            <v-divider
              class="mb-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-3 pb-0">
              <v-row
                align="center"
                justify="center">
                <!-- CAMERA -->
                <v-col cols="4" class="text-center">
                  <div
                    v-on:keyup.enter="message.date = 'hari ini'; fetchLastAttendance(); dialog.date = false;"
                    @click="message.date = 'hari ini'; fetchLastAttendance(); dialog.date = false;"
                    class="cursor-pointer">
                    <v-avatar
                      size="50"
                      class="cursor-pointer"
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img
                        alt="utc"
                        :src="require('@/assets/icon/calendar.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div
                      class="font-weight-bold black--text mt-2">
                      Hari Ini
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="4"
                  class="text-center px-0">
                  <div
                    class="d-flex align-center">
                    <v-divider
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />

                    <div
                      class="mx-2 font-weight-bold"
                      style="opacity: 80%;">
                      Atau
                    </div>

                    <v-divider
                      style="border: 2px solid #eaeaea; border-radius: 50px;" />
                  </div>
                </v-col>

                <!-- FOLDER -->
                <v-col
                  cols="4"
                  class="text-center">
                  <div
                    v-on:keyup.enter="message.date = 'kemarin'; fetchLastAttendance(); dialog.date = false;"
                    @click="message.date = 'kemarin'; fetchLastAttendance(); dialog.date = false;"
                    class="cursor-pointer">
                    <v-avatar
                      size="50"
                      class="cursor-pointer"
                      v-ripple
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;">
                      <v-img
                        alt="utc"
                        :src="require('@/assets/icon/yesterday.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <div
                      class="font-weight-bold black--text mt-2">
                      Kemarin
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mt-2"
              style="border-top: 2px dashed #e0e0e0;"/>

            <v-card-actions
              class="pa-4">
              <v-spacer/>

              <!-- <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="saveCheckInOut()"
                @click="saveCheckInOut()"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn>

              <v-btn
                :disabled="process.form"
                :loading="process.form"
                v-on:keyup.enter="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                @click="sheet.camera_attendance = true; selected.type_attendance = 'camera';"
                color="#348d37"
                elevation="0"
                class="font-weight-bold text-capitalize border-radius white--text">
                <v-icon small class="mr-1">mdi-check-bold</v-icon>
                Ya, Saya Yakin
              </v-btn> -->

              <v-btn
                @click="dialog.date = false"
                :disabled="process.form"

                rounded
                color="red"
                outlined

                class="font-weight-bold text-capitalize red--text">
                <v-icon small class="mr-1">mdi-close-thick</v-icon>
                Batal
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            height="175"
            :color="set_color"
            class="mb-16"
            style="border-radius: 0px 0px 30px 30px;">
            <v-card-text
              class="pt-16 mt-6">
              <v-card
                color="white"
                style="box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; border-radius: 30px; z-index: 1;">
                <v-list>
                  <v-skeleton-loader
                    :loading="Object.keys(company).length < 1"
                    type="list-item-avatar-two-line">
                    <div>
                      <v-list-item v-if="Object.keys(company).length > 0">
                        <v-list-item-avatar
                          size="50"
                          style="border: 2px solid #e7e9ec;">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="utc"
                            :src="user.company_logo_image_url !== '' ? user.company_logo_image_url : require('@/assets/logo/favicon.jpg')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="20"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item-avatar>

                        <!-- <v-list-item-avatar
                          size="50"
                          color="white">
                          <v-avatar
                            size="50"
                            style="border: 3px solid #e7e9ec;">
                            <v-img
                              contain
                              width="100%"
                              height="100%"
                              alt="utc"
                              :src="user.image_url === '' ? require('@/assets/logo/favicon.jpg') : user.image_url"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </v-list-item-avatar> -->

                        <v-list-item-content>
                          <v-list-item-title
                            class="body-2 font-weight-bold black--text mb-2">
                            <!-- {{ user.name }} -->

                            {{ user.company_name }}
                          </v-list-item-title>

                          <v-list-item-subtitle
                            class="body-2 text-capitalize">
                            Unit {{ user.unit_name }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <!-- <v-list-item-action
                          class="mt-0 mb-5">
                          <v-icon size="26" color="black">mdi-chevron-down</v-icon>
                        </v-list-item-action> -->
                      </v-list-item>
                    </div>
                  </v-skeleton-loader>
                </v-list>

                <v-divider
                  class="mx-4 my-1"
                  style="border-top: 1px solid #e0e0e0;" />

                <v-card-text
                  class="pb-0">
                  <div
                    class="d-flex align-center">
                    <v-avatar
                      size="24"
                      tile>
                      <v-img
                        contain
                        alt="utc"
                        :src="require('@/assets/icon/calendar.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 ml-2">
                      {{ datenow | weekday }}, {{ datenow | date }}
                    </span>

                    <div class="ml-auto" v-if="user.role === 'security'">
                      <v-btn
                        rounded
                        @click="dialog.date = true"
                        block
                        :color="set_color"
                        elevation="0"
                        small

                        class="caption text-capitalize font-weight-bold white--text">
                        Pilih Tanggal
                      </v-btn>
                    </div>
                  </div>

                  <div v-if="!process.attendance && detail.status !== 'ijin' && detail.status !== 'leave'"
                    class="body-2 mt-2">
                    <span v-if="Object.keys(detail).length < 1"
                      class="red--text">
                      Anda belum melakukan presensi hari ini
                    </span>

                    <span v-else>
                      <span v-if="!detail.end_bool"
                        class="orange--text">
                        Anda belum melakukan presensi pulang
                      </span>

                      <span v-else
                        class="green--text">
                        Anda sudah melakukan presensi hari ini
                      </span>
                    </span>
                  </div>

                  <div v-if="!process.attendance && detail.status === 'leave'"
                    @click="dialog.alasan = true"
                    class="body-2 mt-2 blue--text one-line cursor-pointer">
                    Anda hari ini sedang meinggalkan jam kerja{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
                  </div>

                  <div v-if="!process.attendance && detail.status === 'ijin'"
                    @click="dialog.alasan = true"
                    class="body-2 mt-2 blue--text one-line cursor-pointer">
                    Anda hari ini sedang izin{{ detail.leave_reason !== '' ? `, dengan alasan ${detail.leave_reason}.` : '.' }}
                  </div>
                </v-card-text>

                <v-card-actions
                  class="px-4">
                  <v-row>
                    <v-col
                      cols="5">
                      <!-- :disabled="(Object.keys(detail).length > 0 && !detail.end_bool) && detail.status === 'ijin'" -->
                      <v-btn
                        @click="reset('datang')"
                        :disabled="Object.keys(detail).length > 0 && !detail.end_bool || process.attendance"

                        rounded
                        block
                        :color="set_color"

                        class="body-2 text-capitalize font-weight-bold white--text">
                        Datang
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="5">
                      <!-- :disabled="Object.keys(detail).length < 1 || detail.end_bool || detail.status === 'ijin'" -->
                      <v-btn
                        @click="reset('pulang')"
                        :disabled="Object.keys(detail).length < 1 || detail.end_bool || process.attendance"

                        rounded
                        block
                        color="#E62129"

                        class="body-2 text-capitalize font-weight-bold white--text">
                        Pulang
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="2">
                      <v-btn
                        to="/attendance"

                        rounded
                        outlined
                        color="#e0e0e0"
                        min-width="36"

                        class="pa-0">
                        <v-avatar
                          size="18"
                          tile>
                          <v-img
                            contain
                            alt="utc"
                            :src="require('@/assets/icon/daily_calendar.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  size="15"
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>

          <v-card
            flat
            class="pt-12"
            color="transparent">
            <v-card-text
              class="mt-5 pb-0">
              <v-row>
                <v-col
                  v-for="(item, index) in menu"
                  :key="index"
                  cols="4">
                  <v-card
                    :to="item.link"
                    height="100"
                    color="white"
                    class="d-flex align-center justify-center"
                    style="box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius: 20px;">
                    <v-card-text
                      class="text-center">
                      <v-avatar
                        tile
                        size="36">
                        <v-img
                          contain
                          alt="utc"
                          :src="item.icon"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-avatar>

                      <div
                        class="caption mt-2">
                        {{ item.title }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              :class="!popup ? 'pb-2' : ''">
              <div
                class="body-1 font-weight-bold black--text"
                :class="!popup ? '' : 'mb-4'">
                Laporan Harian
              </div>

              <v-card v-if="popup"
                flat
                :color="list.report.length < 1 ? '#FBC460' : '#348d37'"
                style="border-radius: 20px;">
                <v-card-text
                  class="body-2 line-text-second pb-0"
                  :class="list.report.length < 1 ? '' : 'white--text'">
                  <span v-if="list.report.length < 1">
                    Anda belum mengirimkan laporan harian. Silahkan klik tombol dibawah untuk membuat laporan.
                  </span>

                  <span v-else>
                    Berikut adalah laporan harian yang Anda kirim, terima kasih Anda telah melakukan kegiatan hari ini.
                  </span>
                </v-card-text>

                <v-card-actions
                  class="pa-4 pt-0">
                  <v-spacer />

                  <v-btn
                    @click="closePopup()"

                    rounded
                    small
                    color="#444444"

                    class="text-capitalize white--text">
                    Tutup
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>

            <v-card-actions
              class="px-4">
              <v-btn
                @click="sheet.add_report = true; reset();"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img
                    contain
                    alt="utc"
                    :src="require('@/assets/icon/add.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="15"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Buat Laporan Harian
                </span>
              </v-btn>
            </v-card-actions>

            <v-card-text>
              <div v-if="list.report.length > 0 || search !== '' || filter.periode !== ''"
                class="d-flex align-center mb-5">
                <v-text-field
                  v-model="search"
                  @click:clear="search = ''; fetchReport();"
                  v-on:keyup.enter="fetchReport()"
                  class="no_box"
                  solo
                  :color="set_color"
                  single-line
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="dialog.filter = true; fetchCategory();"
                  fab
                  outlined
                  color="#e0e0e0"
                  width="48"
                  height="48"
                  class="ml-4">
                  <v-avatar
                    size="18"
                    tile>
                    <v-icon color="#6f6f6f">mdi-filter-multiple-outline</v-icon>
                  </v-avatar>
                </v-btn>
              </div>

              <!-- <v-text-field v-if="list.report.length > 0 || search !== ''"
                v-model="search"
                @click:clear="search = ''; fetchReport();"
                v-on:keyup.enter="fetchReport()"
                class="no_box mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field> -->

              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.report ? 3 : list.report"
                    :key="index"
                    :loading="process.report"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.report && list.report.length > 0"
                          @click="selected.report = item; sheet.detail_report = true;"
                          :class="index < list.report.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="set_color"
                                x-small>
                                {{ item.category_title }}
                              </v-chip>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <div v-if="pagination.report.total_page > 1"
                class="d-flex align-center mt-4">
                <v-spacer />

                <v-btn
                  to="/report"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize">
                  semua laporan

                  <v-icon class="ml-1">mdi-pan-right</v-icon>
                </v-btn>

                <v-spacer />
              </div>
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.add_report = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Tambah Laporan
              </span>
            </v-card-title>

            <v-card-text
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver
                  ref="form">
                  <v-form>
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Judul
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="saveReport()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Besok Rapat"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.title"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="category_id"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Kategori
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="saveReport()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Aktifitas"

                        :items="category"
                        item-text="title"
                        item-value="id"
                        :item-color="set_color"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.category_id"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <div
                      class="mb-6">
                      <div class="body-2">
                        Lampiran
                        <span>
                          (boleh dikosongi)
                        </span>
                      </div>

                      <v-row>
                        <v-col
                          cols="6">
                          <uploadfolder
                            v-model="upload.folder">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.file || process.doc"
                                :loading="process.file"

                                rounded
                                large
                                block
                                elevation="0"
                                :color="`${set_color_rgb_soft}`"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="utc"
                                    :src="require('@/assets/icon/upload_image.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload Gambar
                                </span>
                              </v-btn>
                            </div>
                          </uploadfolder>
                        </v-col>

                        <v-col
                          cols="6">
                          <uploaddocument
                            v-model="upload.doc">
                            <div slot="activator">
                              <v-btn
                                :disabled="process.doc || process.file"
                                :loading="process.doc"

                                rounded
                                large
                                block
                                elevation="0"
                                :color="`${set_color_rgb_soft}`"

                                class="body-2 font-weight-bold text-capitalize d-flex align-center"
                                :style="`color: ${set_color};`">
                                <div>
                                  <v-img
                                    contain
                                    width="20"
                                    height="20"
                                    alt="utc"
                                    :src="require('@/assets/icon/upload_folder.png')">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          indeterminate
                                          size="20"
                                          color="white">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </div>

                                <span
                                  class="ml-2">
                                  Upload PDF
                                </span>
                              </v-btn>
                            </div>
                          </uploaddocument>
                        </v-col>
                      </v-row>

                      <div v-if="form.attachment === ''"
                        class="line-text-second font-italic"
                        style="opacity: 70%;">
                        Upload Gambar atau PDF untuk melengkapi data lampiran dari laporan Anda.
                      </div>

                      <div v-if="form.attachment !== ''"
                        class="mt-2">
                        <div v-if="form.attachment.split('.').pop() !== 'pdf'">
                          <v-img
                            contain
                            alt="utc"
                            width="100%"
                            height="auto"
                            :src="form.attachment"
                            class="ma-auto border-radius">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </div>

                        <div v-else>
                          <iframe
                            :src="`${form.attachment}#toolbar=0`"
                            allowfullscreen
                            frameborder="0"
                            width="100%"
                            height="397"
                            style="border-radius: 15px; border: 2px solid #323639;">
                          </iframe>
                        </div>
                      </div>


                      <!-- <uploadfolder
                        v-model="upload.folder">
                        <div
                          slot="activator"
                          class="cursor-pointer">
                          <v-card
                            flat
                            width="100%"
                            height="260"
                            class="d-flex align-center justify-center"
                            style="border-radius: 15px;
                                  border: 2px solid #eaeaea;
                                  border-style: dashed;">
                            <v-img v-if="!process.file && form.attachment !== ''"
                              contain
                              alt="kominfo"
                              width="100%"
                              height="256"
                              :src="form.attachment"
                              class="ma-auto d-flex align-center text-center">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>

                              <v-icon
                                size="65"
                                color="grey">
                                mdi-cloud-upload-outline
                              </v-icon>
                            </v-img>

                            <v-icon v-if="!process.file && form.attachment === ''"
                              size="65"
                              color="grey">
                              mdi-cloud-upload-outline
                            </v-icon>

                            <v-progress-circular v-if="process.file"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-card>
                        </div>
                      </uploadfolder> -->
                    </div>

                    <ValidationProvider
                      name="desc"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="saveReport()"

                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.desc"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row
                  align="center">
                  <v-col
                    cols="3"
                    class="pb-0">
                    <v-img
                      contain
                      alt="utc"
                      :src="require('@/assets/icon/report.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col
                    cols="9"
                    class="black--text pb-0">
                    <div>
                      <div
                        class="body-2 line-text-second black--text"
                        style="opacity: 60%;">
                        Silahkan isi laporan kegiatan yang Anda lakukan hari ini, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-4">
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="saveReport()"
                      @click="saveReport()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_report = false"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_report"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_report = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Laporan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.report).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1 d-flex align-center">
                <v-icon x-small>mdi-calendar-month-outline</v-icon>

                <span
                  class="ml-1">
                  {{ selected.report.datetime | date }} Pukul {{ selected.report.datetime | time }}
                </span>
              </div>

              <div
                class="title font-weight-bold black--text">
                {{ selected.report.title }}
              </div>

              <v-divider
                class="mt-2 mb-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Kategori:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.category_title }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-2">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second mb-6">
                {{ selected.report.desc !== '' ? selected.report.desc : '-' }}
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lampiran:
              </div>

              <div
                class="mb-6">
                <!-- <v-img
                  width="100%"
                  height="auto"
                  alt="utc"
                  class="border-radius"
                  :src="selected.report.attachment">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img> -->

                <div v-if="selected.report.attachment !== ''">
                  <div v-if="selected.report.attachment.split('.').pop() !== 'pdf'">
                    <v-img
                      contain
                      alt="utc"
                      width="100%"
                      height="auto"
                      :src="selected.report.attachment"
                      class="ma-auto border-radius">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div v-else>
                    <iframe
                      :src="`${selected.report.attachment}#toolbar=0`"
                      allowfullscreen
                      frameborder="0"
                      width="100%"
                      height="397"
                      style="border-radius: 15px; border: 2px solid #323639;">
                    </iframe>
                  </div>
                </div>

                <div v-else
                  class="pa-4"
                  style="border: 2px dashed #eaeaea; border-radius: 10px;">
                  Data lampiran tidak tersedia!!!
                </div>
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                Lokasi:
              </div>

              <div>
                <gmap-map
                  ref="gmap"
                  :center="center"
                  :zoom="15"
                  :scrollwheel="false"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  style="width:100%; height: 30vh;">
                  <gmap-marker
                    :position="currentPosition.latlng"
                    :icon="currentPosition.icon">
                  </gmap-marker>
                </gmap-map>
              </div>
            </v-card-text>

            <!-- <v-card
              flat
              color="#FFFFFF"
              min-height="50"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0">
                    <v-btn
                      @click="sheet.detail_summary = true; fetchSummary();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      :color="set_color"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-history</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Summary
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card> -->
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_summary"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_summary = false"
              class="cursor-pointer pa-4"
              style="border-radius: 0px;
                    position: absolute;
                    width: 100%;
                    z-index: 2;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Summary Laporan
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.summary).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="title font-weight-bold black--text text-center mb-1">
                {{ selected.summary.user_name }}
              </div>

              <div
                class="body-1 black--text text-center">
                Total: {{ selected.summary.total }}
              </div>

              <v-divider
                class="my-6"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <v-text-field
                v-model="search_history"
                @click:clear="search_history = ''; fetchHistory();"
                v-on:keyup.enter="fetchHistory()"
                class="no_box mt-6 mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.report ? 3 : history"
                    :key="index"
                    :loading="process.report"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.report && history.length > 0"
                          @click="selected.report = item; sheet.detail_report = true; sheet.detail_summary = false;"
                          :class="index < history.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="mb-3">
                              <v-chip
                                dark
                                :color="set_color"
                                x-small>
                                {{ item.category_title }}
                              </v-chip>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.datetime | date }} Pukul {{ item.datetime | time }}
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold mb-2">
                              {{ item.title }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.desc }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.report && pagination_history.total_data > 10 && limit_history < pagination_history.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit_history += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.report && pagination_history.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Riwayat Laporan" />
            </v-card-text>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.camera_attendance"
          hide-overlay
          persistent>
          <uploadcamera v-if="selected.type_attendance === 'camera'"
            :sheet="sheet.camera_attendance"
            @frame="sheet.camera_attendance = $event"
            @input="addCamera($event)"
            @close="sheet.camera_attendance = $event; selected.type_attendance = '';" />
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  const Cookie = process.client ? require('js-cookie') : undefined
  import { createSEOMeta } from '@/utils/seo'
  import { db } from '@/plugins/Firebase'
  import moment from 'moment'
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        datenow: new Date(),

        dialog: {
          form: false,
          success: false,
          option: false,

          permission: false,

          alasan: false,

          error: false,

          filter: false,
          date: false
        },

        filter: {
          category_id: '',
          periode: '',
          periode_format: ''
        },

        menu: [
          {
            title: 'Gaji',
            icon: require('@/assets/image/home/gaji.png'),
            link: 'sallary'
          },
          {
            title: 'Reimburse',
            icon: require('@/assets/image/home/reimburs.png'),
            link: 'reimbursment'
          },
          {
            title: 'Lembur',
            icon: require('@/assets/image/home/lembur.png'),
            link: 'overtime'
          },
          {
            title: 'Pribadi',
            icon: require('@/assets/image/home/pribadi.png'),
            link: 'docprivate'
          },
          {
            title: 'Perusahaan',
            icon: require('@/assets/image/home/perusahaan.png'),
            link: 'doccompany'
          },
          {
            title: 'Scan QR',
            icon: require('@/assets/image/home/qrscan.png'),
            link: 'event'
          }
        ],

        picker: {
          periode: false
        },

        sheet: {
          add_report: false,
          detail_report: false,
          camera_attendance: false,

          detail_summary: false
        },

        selected: {
          report: {},
          type_attendance: '',

          summary: {}
        },

        search: '',
        search_history: '',

        center: {
          lat: null,
          lng: null
        },
        currentPosition: {},

        have_shift: false,

        shift: [],

        detail: {},
        category: [],
        history: [],
        list: {
          report: []
        },
        pagination: {
          report: {}
        },
        pagination_history: {},

        limit_history: 10,

        upload: {
          folder: null,
          doc: null
        },

        data_routes: {},
        doc_id: '',

        form: {
          id: '',
          start_datetime: '',
          end_datetime: '',
          latitude: '',
          longitude: '',
          type: '',
          start_image_url: '',
          end_image_url: '',

          shift_id: '',
          shift_name: '',

          // REPORT
          title: '',
          desc: '',
          attachment: '',

          category_id: ''
        },

        process: {
          attendance: false,
          report: false,
          form: false,
          file: false,
          doc: false,

          limit: false
        },
        message: {
          success: '',
          error: '',
          date: 'hari ini'
        },

        // SEO
        content: {
          url: '/home',
          title: 'Beranda',
          description: 'Beranda UCT',
          image: require('@/assets/logo/favicon.jpg')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      uploadfolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      uploadcamera: () => import(/* webpackPrefetch: true */ '@/components/upload/camera.vue'),
      uploaddocument: () => import(/* webpackPrefetch: true */ '@/components/upload/document.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.attachment = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'upload.doc': {
        handler: function () {
          if (this.upload.doc) {
            this.process.doc = this.upload.doc.process

            if (!this.process.doc) {
              // CALLBACK FROM UPLOAD DOC

              this.form.attachment = this.upload.doc.fileuri
            }
          }
        },
        deep: true
      },

      'selected.report': function(val) {
        this.$gmapApiPromiseLazy().then(() => {
          this.center = {
            lat: parseFloat(val.latitude),
            lng: parseFloat(val.longitude)
          }

          this.currentPosition = {
            latlng: {
              lat: parseFloat(val.latitude),
              lng: parseFloat(val.longitude)
            },
            icon: {
              url: require('@/assets/icon/location_pin.png'),
              scaledSize: new google.maps.Size(50, 50),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(25, 50)
            }
          }
        })
      },

      'limit_history': function() {
        this.process.limit = true

        this.fetchHistory('limit')
      },

      'filter.periode': function(val) {
        this.filter.periode_format = moment(val).format('MMMM YYYY')
      },

      'form.shift_id': function() {
        let self = this
        let data = this.shift.filter(function(v) {
          return self.form.shift_id == v.id
        })

        if (data.length > 0) {
          this.form.shift_name = data[0].name
        }
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      popup () {
        return JSON.parse(this.$store.state.popup.popup)
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      company () {
        return this.$store.state.header.config.company
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb_soft () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.05)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      fetch () {
        this.fetchLastAttendance()
        this.fetchReport()
      },
      async fetchLastAttendance () {
        this.process.attendance = true
        this.datenow = this.message.date === 'hari ini' ? new Date() : new Date(new Date().setDate(new Date().getDate()-1))
        let params = {
          tanggal: this.message.date === 'hari ini' ? moment(new Date()).format('YYYY-MM-DD') :  moment(new Date(new Date().setDate(new Date().getDate()-1))).format('YYYY-MM-DD'),
        }

        await this.$axios.$get(`${process.env.API_URL}user/attendance/last`, { params })
        .then((response) => {
          this.process.attendance = false

          if (response.status === 200) {
            this.detail = response.results.data.data.length > 0 ? response.results.data.data[0] : {}
          }
        })
      },

      async fetchReport () {
        this.process.report = true

        let params = {
          limit: 3,
          search: this.search,

          category_id: this.filter.category_id,
          datetime: this.filter.periode
        }

        await this.$axios.$get(`${process.env.API_URL}user/report/data`, { params })
        .then((response) => {
          this.process.report = false

          if (response.status === 200) {
            this.list.report = response.results.data
            this.pagination.report = response.results.pagination
          }
        })
      },

      async fetchCategory () {
        let params = {
          limit: 100000
        }

        await this.$axios.$get(`${process.env.API_URL}admin/task_category/data`, { params })
        .then((response) => {
          if (response.status === 200) {
            this.category = response.results.data
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              self.form = {
                id: Object.keys(self.detail).length > 0 ? self.detail.id : '',
                start_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                end_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
                // latitude: -7.8032076,
                // longitude: 110.3573354,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                // latitude: -7.7652133,
                // longitude: 110.5325019,
                type: item !== undefined ? item : '',
                start_image_url: '',
                end_image_url: '',

                shift_id: '',

                // REPORT
                title: '',
                desc: '',
                attachment: '',

                category_id: ''
              }
            })

            if (item !== undefined) self.dialog.form = true

            if (self.sheet.add_report) self.fetchCategory()
          } else {
            if (item !== undefined) self.dialog.permission = true
          }
        })

        if (item === 'datang') {
          this.fetchProfile()

          this.fetchShift()
        }

        this.process.form = false

        this.message.error = ''
      },

      async fetchShift () {
        let params = {
          unit_id: this.user.unit_id
        }

        await this.$axios.$get('admin/user_shift/data', { params })
        .then(response => {
          if (response.status === 200) {
            this.shift = response.results.data
          }
        })
      },

      async fetchProfile () {
        let params = {

        }

        await this.$axios.$get('user/profile/detail', { params })
        .then(response => {
          if (response.status === 200) {
            if (response.results.data.shift_id !== null && response.results.data.shift_id !== '' && response.results.data.shift_id !== 0) {
              this.have_shift = true
            }
          }
        })
      },

      addCamera (e) {
        this.process.form = e.process

        if (!e.process) {
          if (this.form.type === 'datang') {
            this.form.start_image_url = e.fileuri
          } else {
            this.form.end_image_url = e.fileuri
          }

          this.sheet.camera_attendance = false

          this.saveCheckInOut()
        }
      },

      async saveCheckInOut () {
        this.message.error = ''

        this.process.form = true

        let url = this.form.type === 'datang' ? 'user/attendance/checkin' : 'user/attendance/checkout'

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API_URL}${url}`, this.form)
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {

            this.dialog.option = false

            this.dialog.success = true

            if (Object.keys(this.user).length > 0) {
              console.log('STEP 1')

              this.fetchCheck()
            }

            this.fetchLastAttendance()

          } else {
            this.dialog.option = false

            this.dialog.error = true
            this.message.error = response.message
          }
        })
      },

      async saveReport () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/report/add`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_report = false

              this.fetchReport()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      closePopup () {
        Cookie.set('popup', false)

        this.$store.state.popup.popup = false
      },

      async fetchSummary () {
        this.process.report = true

        await this.$axios.$get(`${process.env.API_URL}user/report/summary`)
        .then((response) => {
          this.process.report = false

          if (response.status === 200) {
            this.selected.summary = response.results.data.length > 0  ? response.results.data[0] : {}

            if (response.results.data.length > 0) this.fetchHistory()
          }
        })
      },

      async fetchHistory (type) {
        this.process.report = type === undefined ? true : false

        let params = {
          limit: this.limit_history,
          search: this.search_history,

          user_id: this.selected.summary.user_id
        }

        await this.$axios.$get(`${process.env.API_URL}user/report/data`, { params })
        .then((response) => {
          this.process.report = false

          this.process.limit = false

          if (response.status === 200) {
            this.history = response.results.data
            this.pagination_history = response.results.pagination
          }
        })
      },


      // INSERT FIREBASE
      async fetchCheck () {
        let ref = db.collection('routes').where('user_id', '==', this.user.id)
        await ref.onSnapshot(snapshot => {

          console.log('STEP 2')

          console.log(snapshot.docChanges().length)

          if (snapshot.docChanges().length < 1) {
            console.log('STEP ADD FIRST')

            this.addFirst()
          } else {
            console.log('STEP FETCH ROUTES')

            this.fetchRoutes()
          }
        })
      },

      async addFirst() {
        let self = this

        // navigator.geolocation.getCurrentPosition(position => {
          self.data_routes = {
            id: self.user.id,
            code: self.user.code,
            name: self.user.name,
            username: self.user.username,
            email: self.user.email,
            mobilephone: self.user.mobilephone,
            address: self.user.address,
            image_url: self.user.image_url,
            position: self.user.position,
            role: self.user.role,
            unit_id: self.user.unit_id,
            unit_name: self.user.unit_name,
            latitude: self.form.latitude,
            longitude: self.form.longitude,
            input_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }
        // })

        console.log('STEP 3', Object.keys(self.data_routes).length)

        if (Object.keys(self.data_routes).length > 0) {
          await db.collection('routes').add({
            user_id: self.user.id
          })
          .then(response => {
            db.collection('routes').doc(response.id).collection('user').add(self.data_routes)
          })
        }
      },

      async fetchRoutes() {
        let ref = db.collection('routes').where('user_id', '==', this.user.id)
        await ref.onSnapshot(snapshot => {
          snapshot.docs.map((doc) => {
            this.doc_id = doc.id

            console.log('STEP 3')

            this.addRoutes()
          })
        })
      },

      async addRoutes() {
        let self = this

        // navigator.geolocation.getCurrentPosition(position => {
          self.data_routes = {
            id: self.user.id,
            code: self.user.code,
            name: self.user.name,
            username: self.user.username,
            email: self.user.email,
            mobilephone: self.user.mobilephone,
            address: self.user.address,
            image_url: self.user.image_url,
            position: self.user.position,
            role: self.user.role,
            unit_id: self.user.unit_id,
            unit_name: self.user.unit_name,
            latitude: self.form.latitude,
            longitude: self.form.longitude,
            input_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }
        // })

        console.log('STEP 4', Object.keys(self.data_routes).length)

        if (Object.keys(self.data_routes).length > 0) {
          await db.collection('routes').doc(self.doc_id).collection('user').add(self.data_routes)
          .then(response => {
            // DATA RESPONSE
            // console.log(self.data_routes)
          })
          .catch(error => {
            console.error('Data tidak terkirim: ', error)
          })
        }
      }
    }
  }
</script>
