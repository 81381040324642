<template>
  <div class="fill-height py-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.error">
          <v-card
            style="border-radius: 30px;">
            <v-card-text
              class="body-2 text-first line-text-second pt-2 pb-6">
              <v-img
                width="150"
                height="150"
                alt="utc"
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="white"
            style="position: fixed;
                  top: 52px;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  v-for="(item, index) in menu"
                  :key="index"
                  cols="4"
                  :class="index === 1 ? 'px-0' : ''">
                  <v-btn
                    @click="selected.menu = item.value; fetch();"
                    small
                    rounded
                    block
                    elevation="0"
                    class="body-2 text-capitalize"
                    :color="selected.menu === item.value ? set_color : '#eeeeee'"
                    :class="selected.menu === item.value ? 'white--text font-weight-bold' : 'grey--text'">
                    {{ item.title }}
                  </v-btn>

                  <div v-if="selected.menu === item.value"
                    class="text-center">
                    <v-icon
                      :color="set_color"
                      size="10">
                      mdi-circle
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            flat
            color="transparent"
            style="margin-top: 70px;">
            <v-card-text>
              <v-btn
                @click="sheet.add_overtime = true; reset(); selected.menu = 1; fetch();"

                rounded
                block
                outlined
                large
                color="#e0e0e0"

                class="body-1 font-weight-bold text-capitalize d-flex align-center">
                <v-avatar
                  size="18"
                  tile>
                  <v-img
                    contain
                    alt="utc"
                    :src="require('@/assets/icon/add_lembur.png')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="15"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="ml-2"
                  :style="`color: ${set_color};`">
                  Ajukan Lembur
                </span>
              </v-btn>

              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                class="no_box mt-6 mb-5"
                solo
                :color="set_color"
                single-line
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-list
                flat
                three-line
                color="transparent">
                <v-list-item-group>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 3 : list"
                    :key="index"
                    :loading="process.load"
                    type="list-item-three-line">
                    <template>
                      <div>
                        <v-list-item v-if="!process.load && list.length > 0"
                          @click="selected.overtime = item; sheet.detail_overtime = true; reset(item);"
                          :class="index < list.length - 1 ? 'mb-4' : ''"
                          class="pl-3"
                          style="box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
                                border-radius: 20px;
                                background: #FFFFFF40;
                                border-bottom-left-radius: 0px;
                                border-top-left-radius: 0px;"
                          :style="`border-left: 4px solid ${set_color};`">
                          <v-list-item-content>
                            <v-list-item-subtitle
                              class="caption mb-1 d-flex align-center">
                              <v-icon x-small>mdi-calendar-month-outline</v-icon>

                              <span
                                class="ml-1">
                                {{ item.date | date }}
                              </span>

                              <v-icon
                                size="20"
                                style="position: absolute; top: 10px; right: 10px;"
                                :color="item.status === 'submission' ? 'blue' :
                                        item.status === 'approved' ? 'green' : 'red'">
                                {{
                                  item.status === 'submission' ? 'mdi-shape-circle-plus' :
                                  item.status === 'approved' ? 'mdi-checkbox-marked-circle-outline' : 'mdi-cancel'
                                }}
                              </v-icon>
                            </v-list-item-subtitle>

                            <v-list-item-title
                              class="body-1 font-weight-bold text-capitalize mb-2">
                              {{ item.day_type }}
                            </v-list-item-title>

                            <v-list-item-subtitle
                              class="body-2">
                              <span
                                class="line-text-first one-line">
                                {{ item.note }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-group>
              </v-list>

              <v-card-text v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center pb-0">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"

                  rounded
                  small
                  outlined
                  :color="set_color"

                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  lebih banyak
                </v-btn>
              </v-card-text>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                :message="selected.menu === 1 ? 'Ajuan' : selected.menu === 2 ? 'Rekomendasi' : 'Riwayat'" />
            </v-card-text>
          </v-card>
        </section>

        <v-bottom-sheet
          v-model="sheet.add_overtime"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.add_overtime = false; reset();"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Tambah Ajuan Lembur
              </span>
            </v-card-title>

            <v-card-text
              class="py-16 mt-3 mb-16 px-4">
              <div class="mb-16">
                <ValidationObserver
                  ref="form">
                  <v-form>
                    <ValidationProvider
                      name="value"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Jumlah Lembur
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"

                        type="number"
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        min="0"

                        placeholder="Contoh. 2"
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        suffix="Jam"

                        v-model="form.value"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <div>
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tanggal Pengajuan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-menu
                        ref="date"
                        min-width="290px"
                        max-width="290px"
                        transition="scale-transition"
                        offset-y
                        :nudge-right="40"
                        :close-on-content-click="false"
                        v-model="picker.date">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="date"
                            rules="required"
                            v-slot="{ errors, valid }">
                            <v-text-field
                              v-on:keyup.enter="save()"

                              solo
                              readonly
                              class="no_box"
                              :color="set_color"
                              placeholder="Contoh. 2022-12-30"

                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              clearable
                              @click:clear="form.date = ''"

                              v-on="on"
                              v-bind="attrs"

                              v-model="form.date"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </template>

                        <v-date-picker
                          :color="set_color"
                          v-model="form.date"
                          @input="picker.date = false">
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <ValidationProvider
                      name="day_type"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Tipe Hari
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-select
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. Hari Kerja"

                        :items="[
                          {
                            text: 'Hari Kerja',
                            value: 'hari kerja'
                          },
                          {
                            text: 'Hari Libur',
                            value: 'hari libur'
                          }
                        ]"
                        item-text="text"
                        item-value="value"
                        :item-color="set_color"

                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                        v-model="form.day_type"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-select>
                    </ValidationProvider>

                    <ValidationProvider
                      name="note"
                      rules="required"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Keterangan
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-textarea
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box_area"
                        :color="set_color"
                        rows="5"
                        placeholder="Contoh. Tuliskan keterangan disini . . ."
                        :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                        v-model="form.note"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-textarea>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row
                  align="center">
                  <v-col
                    cols="3"
                    class="pb-0">
                    <v-img
                      contain
                      alt="utc"
                      :src="require('@/assets/icon/date.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col
                    cols="9"
                    class="black--text pb-0">
                    <div>
                      <div
                        class="body-2 line-text-second black--text"
                        style="opacity: 60%;">
                        Silahkan isi data untuk mengajukan lembur Anda, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-4">
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save()"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.add_overtime = false; reset();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_overtime"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat>
            <v-card-title
              @click="sheet.detail_overtime = false"
              class="cursor-pointer pa-4"
              style="position: absolute;
                    width: 100%;
                    z-index: 2;
                    border-radius: 0px 0px 30px 30px;"
              :style="`background: ${set_color};`">
              <v-icon
                color="white">
                mdi-arrow-left-thick
              </v-icon>

              <span
                class="body-1 font-weight-bold text-capitalize ml-1 white--text">
                Detail Lembur
              </span>
            </v-card-title>

            <v-card-text v-if="Object.keys(selected.overtime).length > 0"
              class="pt-16 mt-3 px-4">
              <div
                class="caption mb-1">
                Jumlah Lembur:
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                {{ selected.overtime.value }} Jam
              </div>

              <div
                class="caption mb-1">
                Tanggal:
              </div>

              <div
                class="body-2 font-weight-bold black--text mb-4">
                {{ selected.overtime.date | date }}
              </div>

              <div
                class="caption mb-1">
                Tipe Hari:
              </div>

              <div
                class="body-2 font-weight-bold black--text text-capitalize mb-4">
                {{ selected.overtime.day_type }}
              </div>

              <div
                class="caption mb-1">
                Keterangan:
              </div>

              <div
                class="body-2 line-text-second black--text">
                {{ selected.overtime.note !== '' ? selected.overtime.note : '-' }}
              </div>

              <v-divider v-if="selected.menu === 2"
                class="my-4"
                style="border-top: 3px solid #f2f2f2; border-radius: 50px;" />

              <ValidationObserver v-if="selected.menu === 2"
                ref="form">
                <v-form>
                  <ValidationProvider
                    name="status"
                    rules="required"
                    v-slot="{ errors, valid }">
                    <div class="body-2 mb-2">
                      <span class="red--text">*</span>
                      Status
                      <span class="error--text">
                        (wajib diisi)
                      </span>
                    </div>

                    <v-select
                      v-on:keyup.enter="save_rekomendasi()"

                      solo
                      class="no_box"
                      :color="set_color"
                      placeholder="Contoh. Setujui"

                      :items="[
                        {
                          text: 'Setujui',
                          value: 'approved'
                        },
                        {
                          text: 'Tolak',
                          value: 'rejected'
                        }
                      ]"
                      item-text="text"
                      item-value="value"
                      :item-color="set_color"

                      :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                      v-model="form.status"
                      :success="valid"
                      :error-messages="errors"
                      required>
                    </v-select>
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>

            <v-card v-if="selected.menu === 2"
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row
                  align="center">
                  <v-col
                    cols="3"
                    class="pb-0">
                    <v-img
                      contain
                      alt="utc"
                      :src="require('@/assets/icon/clock_check.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col
                    cols="9"
                    class="black--text pb-0">
                    <div>
                      <div
                        class="body-2 line-text-second black--text"
                        style="opacity: 60%;">
                        Silahkan pilih Setuju / Tolak atas rekomendasi lembur dari Admin, kemudian klik <b class="font-italic">Simpan</b> untuk kirim.
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mt-4">
                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save_rekomendasi()"
                      @click="save_rekomendasi"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"

                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pb-0">
                    <v-btn
                      @click="sheet.detail_overtime = false; reset();"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined

                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar
                        size="25"
                        style="position: absolute;
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          error: false
        },

        sheet: {
          add_overtime: false,
          detail_overtime: false
        },

        menu: [
          {
            title: 'Ajuan',
            value: 1
          },
          {
            title: 'Rekomendasi',
            value: 2
          },
          {
            title: 'Riwayat',
            value: 3
          }
        ],

        selected: {
          overtime: {},
          menu: 1
        },

        search: '',

        list: [],
        pagination: {},
        limit: 10,
        picker: {
          date: false
        },

        form: {
          id: '',
          user_id: '',
          value: '',
          date: '',
          note: '',
          day_type: '',

          // APPROVED / REJECTED
          approve_reject_id: '',
          status: ''
        },

        process: {
          load: false,
          form: false,

          limit: false
        },
        message: {
          success: '',
          error: ''
        },

        // SEO
        content: {
          url: '/overtime',
          title: 'Lembur',
          description: 'Lembur UCT',
          image: require('@/assets/logo/favicon.jpg')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit': function() {
        this.process.limit = true

        this.fetch('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let filters = this.selected.menu === 1 || this.selected.menu === 2 ? { status: 'submission' } : { 'status[neq]' : 'submission' }

        let params = {
          limit: this.limit,
          search: this.search,
          submission_by: this.selected.menu === 1 ? 'user' : this.selected.menu === 2 ? 'admin' : ''
        }

        Object.assign(params, filters)

        await this.$axios.$get(`${process.env.API_URL}user/overtime/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          user_id: this.user.id,
          value: '',
          date: '',
          note: '',
          day_type: '',

          // APPROVED / REJECTED
          approve_reject_id: '',
          status: ''
        }

        this.picker = {
          date: false
        }

        this.process.form = false

        this.message.error = ''
      },

      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/overtime/submission`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.add_overtime = false

              this.fetch()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      // REKOMENDASI LEMBUR
      async save_rekomendasi () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/overtime/approval`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {

              this.sheet.detail_overtime = false

              this.selected.menu = 2

              this.fetch()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>
