<template>
  <div class="fill-height">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.success">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="pt-2 pb-6">
              <v-img 
                width="125"
                height="125"
                alt="utc" 
                :src="require('@/assets/icon/info_berhasil.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                <!-- Terima kasih, Anda telah mengubah password untuk keamanan data pribadi Anda di UCT. -->
                Terima kasih, Anda telah mengubah password untuk keamanan data pribadi Anda di HRIS.
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.error">
          <v-card 
            style="border-radius: 30px;">
            <v-card-text 
              class="pt-2 pb-6">
              <v-img 
                width="150"
                height="150"
                alt="utc" 
                :src="require('@/assets/icon/info_warning.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular 
                      size="20"
                      indeterminate 
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div 
                class="body-1 text-center line-text-second">
                {{ message.error }}
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <section>
          <v-card 
            flat
            min-height="100vh"
            color="transparent">
            <v-card-text 
              class="py-8"
              style="margin-bottom: 120px;">
              <div>
                <ValidationObserver ref="form">
                  <v-form class="text-left mt-12">
                    <ValidationProvider 
                      name="old_password" 
                      rules="required|min:8|max:20"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password Lama
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. ******"
                        :type="show.old_password ? 'text' : 'password'"

                        :append-icon="show.old_password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.old_password = !show.old_password"
                        :counter="20"

                        v-model="form.old_password"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider 
                      name="new_password" 
                      rules="required|min:8|max:20"
                      v-slot="{ errors, valid }">
                      <div class="body-2 mb-2">
                        <span class="red--text">*</span>
                        Password Baru
                        <span class="error--text">
                          (wajib diisi)
                        </span>
                      </div>

                      <v-text-field
                        v-on:keyup.enter="save()"

                        solo
                        class="no_box"
                        :color="set_color"
                        placeholder="Contoh. ******"
                        :type="show.new_password ? 'text' : 'password'"
                        
                        :append-icon="show.new_password ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show.new_password = !show.new_password"
                        :counter="20"
                        
                        v-model="form.new_password"
                        :success="valid"
                        :error-messages="errors"
                        required>
                      </v-text-field>
                    </ValidationProvider>
                  </v-form>
                </ValidationObserver>
              </div>
            </v-card-text>

            <v-card
              flat
              color="#FFFFFF"
              min-height="150"
              style="border-radius: 15px 15px 0px 0px;
                    position: fixed;
                    bottom: 0;
                    width: 460px;
                    z-index: 1;">
              <v-card-text
                class="pa-4">
                <v-row 
                  align="center">
                  <v-col 
                    cols="3" 
                    class="pb-0">
                    <v-img 
                      contain
                      alt="utc" 
                      :src="require('@/assets/image/profile/password.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular 
                            indeterminate 
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-col>

                  <v-col 
                    cols="9" 
                    class="black--text pb-0">
                    <div>
                      <div 
                        class="body-2 line-text-second black--text" 
                        style="opacity: 60%;">
                        Masukkan password lama dan password baru Anda, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>
                    </div>
                  </v-col>
                </v-row>
                
                <v-row 
                  class="mt-4">
                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn
                      v-on:keyup.enter="save()"
                      @click="save"
                      :disabled="process.form"
                      :loading="process.form"

                      rounded
                      block
                      large
                      color="#348d37"
                      
                      class="body-1 font-weight-bold text-capitalize white--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-check-bold</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Simpan
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col 
                    cols="6" 
                    class="pb-0">
                    <v-btn 
                      @click="$emit('close', false)"
                      :disabled="process.form"

                      rounded
                      block
                      large
                      color="red"
                      outlined
                      
                      class="body-1 font-weight-bold text-capitalize red--text pl-1 pr-2">
                      <v-avatar 
                        size="25"
                        style="position: absolute; 
                              left: 2px;">
                        <v-icon size="20">mdi-close-thick</v-icon>
                      </v-avatar>

                      <span class="ml-6">
                        Batal
                      </span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card>
        </section>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: {
          success: false,
          error: false
        },
        show: {
          old_password: false,
          new_password: false
        },
        form: {
          old_password: '',
          new_password: ''
        },
        process: {
          form: false
        },
        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      
    },
    watch: {
      
    },
    computed: {
      rps() {
        return this.$vuetify.breakpoint.name
      },
      set_color () {
        return this.$store.state.header.config.color
      }
    },
    mounted () {
      
    },
    methods: {
      reset () {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          old_password: '',
          new_password: ''
        }

        this.process.form = false

        this.message.error = ''
      },

      async save() {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API_URL}user/profile/change_password`, this.form)
          .then((response) => {

            this.process.form = false

            if (response.status === 200) {
              
              this.$emit('close', false)

              this.dialog.success = true

              this.reset()

            } else {
              this.dialog.error = true
              this.message.error = response.message
            }
          })
          
        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      }
    }
  }
</script>